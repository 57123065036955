import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73'),
	() => import('./nodes/74'),
	() => import('./nodes/75'),
	() => import('./nodes/76'),
	() => import('./nodes/77'),
	() => import('./nodes/78'),
	() => import('./nodes/79'),
	() => import('./nodes/80'),
	() => import('./nodes/81'),
	() => import('./nodes/82'),
	() => import('./nodes/83'),
	() => import('./nodes/84'),
	() => import('./nodes/85'),
	() => import('./nodes/86'),
	() => import('./nodes/87'),
	() => import('./nodes/88')
];

export const server_loads = [0,2,4,6,10,11,12,13];

export const dictionary = {
		"/": [~19],
		"/admin": [~20,[2]],
		"/admin/audit-log": [~24,[2]],
		"/admin/labs": [~25,[2]],
		"/admin/materials": [~26,[2]],
		"/admin/permissions": [~27,[2]],
		"/admin/[distChannel]/materials": [~21,[2]],
		"/admin/[distChannel]/materials/[code]": [~22,[2]],
		"/admin/[distChannel]/prices": [~23,[2]],
		"/api/orders/[orderId]/[eye]/lens/profile": [~28],
		"/api/v1/docs": [~29],
		"/billing": [~30,[3]],
		"/billing/[distChannel]": [~31,[3]],
		"/billing/[distChannel]/orders": [~32,[3]],
		"/billing/[distChannel]/orders/[orderId]": [~33,[3]],
		"/clinic": [~34,[4]],
		"/clinic/admin": [~35,[4]],
		"/clinic/orders": [~36,[4]],
		"/clinic/orders/[orderId]": [~37,[4]],
		"/clinic/orders/[orderId]/reorder": [~39,[4]],
		"/clinic/orders/[orderId]/[eye]/lens/update": [~38,[4]],
		"/distributor": [~40,[5]],
		"/distributor/[distChannel]": [~41,[5,6]],
		"/distributor/[distChannel]/calculators": [~42,[5,6]],
		"/distributor/[distChannel]/calculators/practices": [~43,[5,6]],
		"/distributor/[distChannel]/calculators/practices/[practiceId]": [~44,[5,6]],
		"/distributor/[distChannel]/calculators/practices/[practiceId]/forge": [~45,[5,6,7]],
		"/distributor/[distChannel]/calculators/practices/[practiceId]/scleral/step1": [~46,[5,6,8]],
		"/distributor/[distChannel]/calculators/practices/[practiceId]/scleral/step2": [~47,[5,6,9]],
		"/distributor/[distChannel]/diagnostic": [~48,[5,6]],
		"/distributor/[distChannel]/labels": [~49,[5,6]],
		"/distributor/[distChannel]/orders": [~50,[5,6]],
		"/distributor/[distChannel]/orders/create": [~55,[5,6]],
		"/distributor/[distChannel]/orders/create/practices": [~56,[5,6]],
		"/distributor/[distChannel]/orders/create/practices/[practiceId]": [~57,[5,6]],
		"/distributor/[distChannel]/orders/[orderId]": [~51,[5,6]],
		"/distributor/[distChannel]/orders/[orderId]/new": [~54,[5,6]],
		"/distributor/[distChannel]/orders/[orderId]/[eye]/lens/spec/[rowNumber]": [~52,[5,6]],
		"/distributor/[distChannel]/orders/[orderId]/[eye]/lens/update": [~53,[5,6]],
		"/distributor/[distChannel]/practices": [~58,[5,6]],
		"/distributor/[distChannel]/practices/[practiceId]": [~59,[5,6]],
		"/kb": [~60,[10]],
		"/kb/search": [~62,[10]],
		"/kb/[slug]": [~61,[10,11]],
		"/lab": [~63],
		"/lab/[labCode]": [64,[12]],
		"/lab/[labCode]/batch/create": [~72,[12]],
		"/lab/[labCode]/batch/list": [~73,[12]],
		"/lab/[labCode]/checklist": [~74,[12]],
		"/lab/[labCode]/clear_submit_bar_codes": [~75,[12]],
		"/lab/[labCode]/errored-orders": [~76,[12]],
		"/lab/[labCode]/lens/material": [~77,[12]],
		"/lab/[labCode]/print/forms": [~78,[12]],
		"/lab/[labCode]/qc": [~79,[12]],
		"/lab/[labCode]/[orderId]/diagnosticSet/qc": [~69,[12]],
		"/lab/[labCode]/[orderId]/print/forms": [~70,[12]],
		"/lab/[labCode]/[orderId]/qc": [~71,[12]],
		"/lab/[labCode]/[orderId]/[eye]/lens/update": [~65,[12]],
		"/lab/[labCode]/[orderId]/[eye]/qc": [~66,[12]],
		"/lab/[labCode]/[orderId]/[eye]/schematic": [~67,[12,13]],
		"/lab/[labCode]/[orderId]/[eye]/schematic/[revision]": [~68,[12,13]],
		"/login": [80,[14]],
		"/orders/[orderId]/lens/[eye]/profile": [~81],
		"/tools": [~82,[15]],
		"/tools/rigid-lens-bvp": [~83,[15,16]],
		"/tools/soft-toric-bvp": [~84,[15,17]],
		"/troubleshoot": [~85,[18]],
		"/troubleshoot/orders": [~86,[18]],
		"/v1/kb": [~87],
		"/v1/kb/[slug]": [~88]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';