import * as Sentry from '@sentry/sveltekit';
import { env } from '$env/dynamic/public';
import { captureConsoleIntegration } from '@sentry/integrations';

Sentry.init({
    dsn: 'https://b8915ac56c954766a4ae08e3e549e200@o447628.ingest.sentry.io/4505089925054464',
    normalizeDepth: 11,
    release: env['PUBLIC_IMAGE_VERSION'],
    environment: env['PUBLIC_WORKSPACE'],
    integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration(),
        captureConsoleIntegration({
            levels: ['error'],
        }),
    ],
});

export const handleError = Sentry.handleErrorWithSentry();
